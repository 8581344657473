<template>
  <app-modal-withdraw
    :show="showModalWithdraw"
    @close="showModalWithdraw = false"
    @refresh="refresh"
  />
  <div>
    <div class="px-4 p-2">
      <div
        class="
          px-5
          py-2
          flex
          m-auto
          lg:px-0
          container
          items-center
          justify-between
        "
      >
        <div>
          <p class="font-medium">{{ $t("wallet.payout.payout") }}</p>
        </div>
      </div>
    </div>
  </div>
  <app-modal-payout-detail
    :show="showModalDetail"
    @close="showModalDetail = false"
    :payout="selectPayout"
    :showBusiness="true"
    processedPaymentLinkRoute="support.balance.transaction.payment-link"
    @update:payout="selectPayout = $event"
  />
  <app-table
    :tabs="tabs"
    :right_tabs="right_tabs"
    :loading="apiPaginationLoading"
    :apiResponse="apiPaginationResponse?.data ?? []"
    @params-changed="paramsChanged"
    @tab-changed="tab_changed"
    :showEmptyButton="false"
    :filters="[
      {
        key: 'filter[business_name]',
        type: 'text',
        placeholder: $t('payout.table.account.header_business_name'),
      },
      {
        key: 'filter[bank_id]',
        type: 'select',
        placeholder: $t('payout.table.account.header_bank_name'),
        items: banks,
      },
      {
        key: 'filter[account_no]',
        type: 'text',
        placeholder: $t('payout.table.account.header_acc_no'),
      },
      {
        key: 'filter[amount]',
        type: 'number',
        placeholder: $t('payout.table.account.header_amount'),
      },
      {
        key: 'filter[currency]',
        type: 'select',
        placeholder: 'Currency',
        value: '',
        items: [
              {
                id: 'MYR',
                name: 'MYR',
              },
              {
                id: 'SGD',
                name: 'SGD',
              },
              {
                id: 'BAHT',
                name: 'BAHT',
              },
              {
                id: 'USD',
                name: 'USD',
              },
              {
                id: 'IDR',
                name: 'IDR',
              },
            ],
      },
      {
        key: 'filter[created_at_between]',
        type: 'date',
        placeholder: 'Created At',
        value: '',
      },
    ]"
    @on-item-click="onItemClick"
  >
    <template v-slot:header>
      <td>
        <b>{{ LabelHeaderDate }}</b>
      </td>
      <td>
        <b>{{ $t("wallet.payout.bank_name") }}</b>
      </td>
      <td>
        <b>{{ $t("wallet.payout.account_no") }}</b>
      </td>
      <td>
        <b>{{ $t("wallet.payout.acc_holder") }}</b>
      </td>
      <td>
        <b>{{ $t("wallet.payout.amount") }}</b>
      </td>
      <td>
        <b>{{ $t("wallet.payout.business_name") }}</b>
      </td>
      <td>
        <b>{{ $t("wallet.payout.status") }}</b>
      </td>
    </template>

    <template v-slot:body="data">
      <td>
        {{ $moment(data.model.updated_at).format("DD MMM YYYY hh:mm a") }}
      </td>
      <td>
        {{ data.model.business.bank?.bank.display_name ?? "-" }}
      </td>
      <td>
        {{ data.model.business.bank?.account_no ?? "-" }}
      </td>
      <td>
        <p>{{ data.model?.business?.bank?.account_holder ?? "-" }}</p>
      </td>
      <td>
        {{ $formats.currency(data.model.currency, data.model.amount ?? 0.0) }}
      </td>
      <td>
        {{ data.model.business?.name ?? "-" }}
      </td>        
      <td>
        <app-badge
          class="capitalize w-24"
          :status="getStatus(data.model?.status)"
        >
          {{ data.model?.status ?? "-" }}
        </app-badge>
      </td>
    </template>

    <template v-slot:footer>
      <tr>
        <td class="font-bold" :colspan="4">
          {{ $t("wallet.transaction.total") }}
        </td>
        <td/>
        <td class="font-bold">
          {{
            $formats.currency(
              apiPaginationResponse?.data?.summary?.currency,
              apiPaginationResponse?.data?.summary?.amount ?? 0
            )
          }}
        </td>        
        <td>&nbsp;</td>
        <td>&nbsp;</td>
      </tr>
    </template>
  </app-table>
</template>

<script>
import PAYOUT_STATUS from "@/utils/const/payout_status";

export default {
  data() {
    return {
      tabs: [],
      right_tabs: [],
      current_tab: null,
      queryParams: "",
      showModalDetail: false,
      showModalWithdraw: false,
      selectPayout: null,
    };
  },

  computed: {
    apiPaginationResponse() {
      return this.$store.getters["walletStore/apiPaginationResponse"];
    },

    apiPaginationLoading() {
      return this.$store.getters["walletStore/apiPaginationLoading"];
    },

    banks() {
      return this.$store.getters["referenceStore/banks"];
    },
    isStatusRequested() {
      return [PAYOUT_STATUS.REQUESTED].includes(this.current_tab);
    },
    isStatusPending() {
      return [PAYOUT_STATUS.PENDING].includes(this.current_tab);
    },
    isStatusProcessing() {
      return [PAYOUT_STATUS.PROCESSING].includes(this.current_tab);
    },
    isStatusApproved() {
      return [PAYOUT_STATUS.APPROVED].includes(this.current_tab);
    },
    isStatusReturn() {
      return [PAYOUT_STATUS.RETURNED].includes(this.current_tab);
    },
    LabelHeaderDate() {
      switch (this.current_tab) {
        case PAYOUT_STATUS.PROCESSING:
          return this.$t("payout.table.account.header_date_processed");
        case PAYOUT_STATUS.PENDING:
          return this.$t("payout.table.account.header_date_pending");
        case PAYOUT_STATUS.APPROVED:
        return this.$t("payout.table.account.header_date_success");
        case PAYOUT_STATUS.RETURNED:
          return this.$t("payout.table.account.header_date_returned");
        default:
          return this.$t("payout.table.account.header_date_requested"); //requested tab
      }
    },
  },

  mounted() {
    this.fetchListPayout();

    this.$store.dispatch("referenceStore/getBanks", 0);

    this.tabs = [
      { key: "requested", title: this.$t("support.payout.tab_request") },
      { key: "processing", title: this.$t("support.payout.tab_process") },
      { key: "pending", title: this.$t("support.payout.tab_pending") },
    ];
    this.right_tabs = [
      { key: "returned", title: this.$t("support.payout.tab_returned") },
      { key: "approved", title: this.$t("support.payout.tab_approved") },
  ]
  },
  methods: {
    getStatus(status) {
      switch (status) {
        case PAYOUT_STATUS.APPROVED:
          return "success";
        case PAYOUT_STATUS.PROCESSING:
        case PAYOUT_STATUS.REQUESTED:
       
          return "light";
          case PAYOUT_STATUS.PENDING:
          return "warning";
      }
    },

    paramsChanged(filters, queryParams) {
      this.queryParams = queryParams;
      this.fetchListPayout();
    },

    tab_changed(tab_name) {
      this.current_tab = tab_name;
    },

    fetchListPayout() {
      this.getTabStatusTab();
      this.$store.dispatch("walletStore/getPayoutForSupport", this.queryParams);
    },

    refresh() {
      window.location.href = this.$route.path;
    },

    getTabStatusTab() {
      var status_tab = null;
      let currentTab = this.current_tab ?? this.$route.query.tab;

      if (currentTab == PAYOUT_STATUS.APPROVED) {
        status_tab = PAYOUT_STATUS.APPROVED;
      } else if (currentTab == PAYOUT_STATUS.PROCESSING) {
        status_tab = PAYOUT_STATUS.PROCESSING;
      } else if (currentTab == PAYOUT_STATUS.REQUESTED) {
        status_tab = PAYOUT_STATUS.REQUESTED;
      } else if (currentTab == PAYOUT_STATUS.PENDING) {
        status_tab = PAYOUT_STATUS.PENDING;
      } else if (currentTab == PAYOUT_STATUS.RETURNED) {
        status_tab = PAYOUT_STATUS.RETURNED;
      } else {
        status_tab = PAYOUT_STATUS.REQUESTED;
      }

      this.queryParams = (this.queryParams == "") ? "?filter[status]=" + status_tab :
        this.queryParams + "&filter[status]=" + status_tab;
    },

    onItemClick(data) {
      this.showModalDetail = !this.showModalDetail;
      this.selectPayout = data;
    },
  },
};
</script>
